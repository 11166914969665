import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Checkbox, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const ServicioFunerario = () => {
  const [funerariosItems, setFunerariosItems] = useState([]);
  const [funerariosItemsSeleccionados, setFunerariosItemsSeleccionados] = useState([]);
  const [tipoSala, setTipoSala] = useState('');
  const [planSeleccionado, setPlanSeleccionado] = useState(null);
  const [mostrarTraslado, setMostrarTraslado] = useState(true);
  const [mostrarPreparacion, setMostrarPreparacion] = useState(true);
  const [mostrarAtaud, setMostrarAtaud] = useState(true);
  const [precioVelacion, setPrecioVelacion] = useState('');
  const [precioTraslado, setPrecioTraslado] = useState('');
  const [precioPreparacion, setPrecioPreparacion] = useState('');
  const [precioAtaud, setPrecioAtaud] = useState('');
  const [selectCliente, setSelectCliente] = useState("");
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [cliente, setCliente] = useState({
    nombre: '',
    cedula: '',
    fecha_nacimiento: '',
    edad: '',
    telefono: '',
    celular: '',
    email: '',
    direccion: '',
    nombre_documento: '',
    documento_identidad: null,
  });

  const handleClienteChange = (e) => {
    const { name, value } = e.target;
    let updatedFields = { [name]: value };
    if (name === 'fecha_nacimiento') {
      const edadCalculada = calcularEdad(value);
      updatedFields.edad = edadCalculada.toString();
    }
    setCliente((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  };

  const calcularEdad = (fechaNacimiento) => {
    const hoy = new Date();
    const fecha_nacimiento = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - fecha_nacimiento.getFullYear();
    const mes = hoy.getMonth() - fecha_nacimiento.getMonth();
    if (mes < 0 || (mes === 0 && hoy.getDate() < fecha_nacimiento.getDate())) {
      edad--;
    }
    return edad;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCliente((prev) => ({
        ...prev,
        documento_identidad: file,
        nombre_documento: file.name,
      }));
    }
  };

  const [difunto, setDifunto] = useState({
    nombre_difunto: '',
    cedula_difunto: '',
    fecha_nac_difunto: '',
    edad_difunto: '',
    parentesco_difunto: '',
    fecha_defuncion: '',
    causa_defuncion: '',
    direccion_defuncion: '',        
  });

  const serviciosFunerarios = {
    sencilla: [
      { id: 1, tipo: 'SALA EDIFICIO CREMATORIO', precio_traslado: 80.00, velacion: '4 HORAS', precio_v: 120.00, precio_d: 100.00, precio_a: 80.00, precio: 380.00 },
      { id: 2, tipo: 'SALA EDIFICIO CREMATORIO', precio_traslado: 80.00, velacion: '6 HORAS', precio_v: 180.00, precio_d: 180.00, precio_a: 150.00, precio: 590.00 },
      { id: 3, tipo: 'SALA EDIFICIO CREMATORIO', precio_traslado: 80.00, velacion: '8 HORAS', precio_v: 240.00, precio_d: 300.00, precio_a: 200.00, precio: 820.00 },
    ],
    vip: [
      { id: 1, tipo: 'SALA VIP EN FUNERARIA', precio_traslado: 80.00, velacion: '4 HORAS', precio_v: 160.00, precio_d: 100.00, precio_a: 80.00, precio: 420.00 },
      { id: 2, tipo: 'SALA VIP EN FUNERARIA', precio_traslado: 80.00, velacion: '6 HORAS', precio_v: 240.00, precio_d: 180.00, precio_a: 150.00, precio: 650.00 },
      { id: 3, tipo: 'SALA VIP EN FUNERARIA', precio_traslado: 80.00, velacion: '8 HORAS', precio_v: 320.00, precio_d: 300.00, precio_a: 200.00, precio: 900.00 },
    ],
  };

  const handleTipoSalaChange = (e) => {
    setTipoSala(e.target.value);
    setPlanSeleccionado(null);
    setPrecioVelacion('');
    setPrecioTraslado('');
    setPrecioPreparacion('');
    setPrecioAtaud('');
  };

  const handlePlanChange = (e) => {
    const plan = serviciosFunerarios[tipoSala].find((p) => p.id === e.target.value);
    setPlanSeleccionado(plan);
    // Al seleccionar el plan, llenamos los estados con los valores
    setPrecioVelacion(plan.precio_v?.toString() || '');
    setPrecioTraslado(plan.precio_traslado?.toString() || '');
    setPrecioPreparacion(plan.precio_d?.toString() || '');
    setPrecioAtaud(plan.precio_a?.toString() || '');
  };

  // Calcular el total del plan seleccionado en el formulario (antes de agregar)
  const totalForm = () => {
    let total = 0;
    total += parseFloat(precioVelacion) || 0;
    if (mostrarTraslado) total += parseFloat(precioTraslado) || 0;
    if (mostrarPreparacion) total += parseFloat(precioPreparacion) || 0;
    if (mostrarAtaud) total += parseFloat(precioAtaud) || 0;
    return total;
  };

  // Calculo del total en el resumen (después de agregar ítems)
  const precioTotalContrato = funerariosItems.reduce((total, item) => {
    let subtotal = item.precioVelacion || 0;
    if (mostrarTraslado) subtotal += (item.traslado || 0);
    if (mostrarPreparacion) subtotal += (item.preparacion || 0);
    if (mostrarAtaud) subtotal += (item.alquiler || 0);
    return total + subtotal;
  }, 0);

  const agregarContrato = () => {
    if (planSeleccionado) {
      const item = {
        descripcion: planSeleccionado.tipo,
        traslado: parseFloat(precioTraslado) || 0,
        velacion: planSeleccionado.velacion,
        precioVelacion: parseFloat(precioVelacion) || 0,
        preparacion: parseFloat(precioPreparacion) || 0,
        alquiler: parseFloat(precioAtaud) || 0,
        precio: totalForm(),
      };

      // Opcional: limpiar campos
      setTipoSala('');
      setPrecioVelacion('');
      setPrecioTraslado('');
      setPrecioPreparacion('');
      setPrecioAtaud('');
      
      item.precioTotal = item.traslado + item.precioVelacion + item.preparacion + item.alquiler;

      setFunerariosItems((prevItems) => [...prevItems, item]); 
      setPlanSeleccionado(''); // Reiniciar el plan seleccionado después de añadirlo
    }
  };  

  const handleSeleccionarFunerariosItem = (index) => {
    const seleccionado = funerariosItemsSeleccionados.includes(index);
    if (seleccionado) {
      setFunerariosItemsSeleccionados(funerariosItemsSeleccionados.filter((i) => i !== index));
    } else {
      setFunerariosItemsSeleccionados([...funerariosItemsSeleccionados, index]);
    }
  };

  const handleEliminarFunerariosItems = () => {
    const nuevosFunerariosItems = funerariosItems.filter((_, index) => !funerariosItemsSeleccionados.includes(index));
    setFunerariosItems(nuevosFunerariosItems);
    setFunerariosItemsSeleccionados([]);
  };

  const handleDifuntoChange = (e) => {
    const { name, value } = e.target;
    let updatedFields = { [name]: value };
    if (name === 'fecha_nac_difunto') {
      const edadCalculada = calcularEdad(value);
      updatedFields.edad_difunto = edadCalculada.toString();
    }
    setDifunto((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  };

  const buscarCliente = async (cedula) => {
    if (!cedula.trim()) return; // Evitar búsquedas con campos vacíos

    try {
        const token = localStorage.getItem("access_token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios.get(`${apiUrl}/api/cliente/${cedula}`, config);

        // Si se encuentra el cliente, actualizar los datos automáticamente
        const clienteEncontrado = response.data;
        toast.success("El cliente ya está registrado. Se asociará el contrato.");

        // Convertir fecha_nacimiento al formato yyyy-MM-dd
        const fechaFormateada = clienteEncontrado.fecha_nacimiento
            ? new Date(clienteEncontrado.fecha_nacimiento).toISOString().split('T')[0]
            : '';

        setClienteSeleccionado(clienteEncontrado); // Establece el cliente seleccionado
        setCliente({
            nombre: clienteEncontrado.nombre,
            cedula: clienteEncontrado.cedula,
            fecha_nacimiento: fechaFormateada, // Fecha en formato yyyy-MM-dd
            edad: clienteEncontrado.edad,
            telefono: clienteEncontrado.telefono,
            celular: clienteEncontrado.celular,
            email: clienteEncontrado.email,
            direccion: clienteEncontrado.direccion,
            documento_identidad: null, // Ignora este campo si no es relevante
            nombre_documento: '',
        });
        //setSelectCliente("existente"); // Cambia el modo automáticamente a Cliente Existente
    } catch (error) {
        if (error.response?.status === 404) {
            toast.info("Cliente no encontrado. Agregue un nuevo cliente.");
        } else {
            console.error("Error al buscar cliente:", error);
            toast.error("Error al buscar cliente.");
        }
    }
  }; 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");
  
    let clienteId;

    try {
      if (selectCliente === "existente" || clienteSeleccionado) {
          // Cliente existente: usar su ID directamente
          if (!clienteSeleccionado || !clienteSeleccionado.id) {
              toast.error("Debe seleccionar un cliente válido.");
              return;
          }
          clienteId = clienteSeleccionado.id;

      } else if (selectCliente === "nuevo") {
          // Validar que todos los campos del cliente nuevo estén completos
          if (!cliente.nombre || !cliente.cedula || !cliente.fecha_nacimiento || !cliente.telefono || !cliente.email || !cliente.direccion) {
              toast.error("Por favor, complete todos los campos obligatorios del cliente.");
              return;
          }

          // Preparar los datos del cliente
          const formData = new FormData();
          Object.keys(cliente).forEach((key) => {
              if (key === "documento_identidad" && cliente[key] instanceof File) {
                  formData.append(key, cliente[key], cliente[key].name);
              } else {
                  formData.append(key, cliente[key]);
              }
          });
  
        const response = await axios.post(`${apiUrl}/api/clientes`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        clienteId = response.data.id;
      }

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        const contratoData = {
          fecha_actual: new Date().toISOString(),
          fecha_vencimiento: null, 
          cliente_id: clienteId,
          vendedor_id: parseInt(localStorage.getItem("vendedor_id")),
          tipo_servicio: 'Servicio Funerario',
          modalidad_pago: 'De Contado',
          precio_total: precioTotalContrato,
          estado: "pre-contrato",      
          detalles: funerariosItems.map(item => ({
              tipo_contrato: 'Servicio Funerario',
              descripcion: item.descripcion,
              precio: 0,
              traslado: item.traslado,
              tiempo_velacion: item.velacion,
              precio_velacion: item.precioVelacion,
              incluye_preparacion: item.preparacion,
              incluye_ataud: item.alquiler,
              modalidadPago: 'De Contado',
              precioTotal: item.precioTotal
          })),
        };     

        const contratoResponse = await axios.post(`${apiUrl}/api/contratos`, contratoData, config);      
        const contratoId = contratoResponse.data.id;
            
        const difuntoData = {
          cliente_id: clienteId,
          contrato_id: contratoId,
          nombre_difunto: difunto.nombre_difunto,
          cedula_difunto: difunto.cedula_difunto,
          fecha_nac_difunto: difunto.fecha_nac_difunto,
          edad_difunto: difunto.edad_difunto,
          parentesco_difunto: difunto.parentesco_difunto,
          fecha_defuncion: difunto.fecha_defuncion,
          causa_defuncion: difunto.causa_defuncion,
          direccion_defuncion: difunto.direccion_defuncion,
        }

        const difuntoResponse = await axios.post(`${apiUrl}/api/difuntos`, difuntoData, config);      
        console.log("Contrato creado:", difuntoResponse.data);        

        toast.success("contrato registrado exitosamente");

        setTimeout(() => {
            setCliente({
                nombre: '',
                cedula: '',
                fecha_nacimiento: '',
                edad: '',
                telefono: '',
                celular: '',
                email: '',
                direccion: '',
                documento_identidad: null,
            });

            setFunerariosItems([]);
            setFunerariosItemsSeleccionados([]);
            setTipoSala('');
            setDifunto({               
              nombre_difunto: '',
              cedula_difunto: '',
              fecha_nac_difunto: '',
              edad_difunto: '',
              parentesco_difunto: '',
              fecha_difuncion: '',
              causa_difuncion: '',
              direccion_difuncion: '',              
            });

        }, 3000); 

    } catch (error) {
        toast.error(`Error: ${error.response?.data?.detail || error.message}`);
    }
  };

  return (
    <Box component="form" 
      onSubmit={handleSubmit}       
      sx={{
        maxWidth: '950px',
        margin: '0 auto',  
        padding: 3,      
        boxShadow: 3,
        backgroundColor: '#ffffff',
        borderRadius: 2,
      }}
    >

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
      />

      <Typography
        sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }}
        variant="h5"
      >
        SOLICITUD DE CONTRATO SERVICIO FUNERARIO
      </Typography>

      <Typography sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
        PLAN A SOLICITAR
      </Typography>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
            <InputLabel>Tipo de Capilla</InputLabel>
            <Select value={tipoSala} label="Tipo de Capilla" onChange={handleTipoSalaChange}>
              <MenuItem value="" disabled selected>Selecciona el tipo de Capilla</MenuItem>
              <MenuItem value="sencilla">Sala Edificio Crematorio</MenuItem>              
              <MenuItem value="vip">Sala VIP en Funeraria</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }} disabled={!tipoSala}>
            <InputLabel>Tiempo de Velación</InputLabel>
            <Select value={planSeleccionado ? planSeleccionado.id : ''} label="Tiempo de Velación" onChange={handlePlanChange}>
              <MenuItem value="" disabled>Selecciona un Plan</MenuItem>
              {tipoSala && serviciosFunerarios[tipoSala].map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>{plan.velacion}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Precio Velación"
            variant="outlined"
            size="small"
            value={precioVelacion}
            onChange={(e) => setPrecioVelacion(e.target.value)}
            sx={{ boxShadow: 3 }}
          />
        </Grid>

        {mostrarTraslado && (
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Precio Traslado"
              variant="outlined"
              size="small"
              value={precioTraslado}
              onChange={(e) => setPrecioTraslado(e.target.value)}
              sx={{ boxShadow: 3 }}
            />
          </Grid>
        )}

        {mostrarPreparacion && (
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Preparación del Difunto"
              variant="outlined"
              size="small"
              value={precioPreparacion}
              onChange={(e) => setPrecioPreparacion(e.target.value)}
              sx={{ boxShadow: 3 }}
            />
          </Grid>
        )}

        {mostrarAtaud && (
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Alquiler del Ataud"
              variant="outlined"
              size="small"
              value={precioAtaud}
              onChange={(e) => setPrecioAtaud(e.target.value)}
              sx={{ boxShadow: 3 }}
            />
          </Grid>
        )}

        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Precio Total"
            variant="outlined"
            size="small"
            value={planSeleccionado ? `$${totalForm().toFixed(2)}` : ''}
            sx={{ boxShadow: 3 }}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={agregarContrato}
            disabled={!planSeleccionado}
          >
            Añadir Contrato
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={3}>
          <Checkbox
            checked={mostrarTraslado}
            onChange={(e) => setMostrarTraslado(e.target.checked)}
            sx={{ color: '#0A4575', '&.Mui-checked': { color: '#0A4575' } }}
          />
          <Typography variant="body1" sx={{ display: 'inline', fontFamily: 'Abel' }}>Servicio de Traslado</Typography>
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={mostrarPreparacion}
            onChange={(e) => setMostrarPreparacion(e.target.checked)}
            sx={{ color: '#0A4575', '&.Mui-checked': { color: '#0A4575' } }}
          />
          <Typography variant="body1" sx={{ display: 'inline', fontFamily: 'Abel' }}>Preparación del Difunto</Typography>
        </Grid>
        <Grid item xs={3}>
          <Checkbox
            checked={mostrarAtaud}
            onChange={(e) => setMostrarAtaud(e.target.checked)}
            sx={{ color: '#0A4575', '&.Mui-checked': { color: '#0A4575' } }}
          />
          <Typography variant="body1" sx={{ display: 'inline', fontFamily: 'Abel' }}>Alquiler de Ataúd</Typography>
        </Grid>
      </Grid>      

      {/* DATOS DEL CLIENTE */}
      <Typography
        sx={{ marginTop: '25px', marginBottom: '7px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
      >
        DATOS DEL CLIENTE
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Nombre cliente"
            variant="outlined"
            size="small"
            name="nombre"
            value={cliente.nombre}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Cédula o Rif"
            variant="outlined"
            size="small"
            name="cedula"
            value={cliente.cedula}
            onChange={handleClienteChange}
            onBlur={(e) => buscarCliente(e.target.value)}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha de Nacimiento"
            variant="outlined"
            type="date"
            size="small"
            name="fecha_nacimiento"
            value={cliente.fecha_nacimiento || ''}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Edad"
            variant="outlined"
            size="small"
            name="edad"
            value={cliente.edad}
            required
            sx={{ boxShadow: 3 }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Teléfono"
            variant="outlined"
            size="small"
            name="telefono"
            value={cliente.telefono}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Celular"
            variant="outlined"
            size="small"
            name="celular"
            value={cliente.celular}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            size="small"
            name="email"
            value={cliente.email}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Dirección"
            variant="outlined"
            size="small"
            name="direccion"
            value={cliente.direccion}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>            
          <input
            accept="image/*"
            id="file-upload"
            type="file"
            required
            style={{
              //visibility: 'hidden',
              height: '1px',
              width: '1px',
              position: 'absolute',
            }}
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              sx={{
                boxShadow: 3,
                backgroundColor: '#0A4575',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#000000',
                },
              }}
            >
              Subir Documento de Identidad
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} sm={2}>
          {cliente.nombre_documento && (
            <Typography sx={{ marginTop: '10px', fontFamily: 'Abel' }}>
              <b>{cliente.nombre_documento}</b>
            </Typography>
          )}            
        </Grid>
      </Grid>

      {/* DATOS DEL DIFUNTO */}
      <Typography
        sx={{ marginTop: '25px', marginBottom: '7px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
      >
        DATOS DEL DIFUNTO
      </Typography>     
      
      <Grid container spacing={3}>                    
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Nombre"
            variant="outlined"
            size="small"
            name="nombre_difunto"
            value={difunto.nombre_difunto || ''}
            onChange={handleDifuntoChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Cédula"
            variant="outlined"
            size="small"
            name="cedula_difunto"
            value={difunto.cedula_difunto || ''}
            onChange={handleDifuntoChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha de Nacimiento"
            variant="outlined"
            type="date"
            size="small"
            name="fecha_nac_difunto"
            value={difunto.fecha_nac_difunto || ''}
            onChange={handleDifuntoChange}
            required
            sx={{ boxShadow: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Edad"
            variant="outlined"
            size="small"
            name="edad_difunto"
            value={difunto.edad_difunto || ''}
            onChange={handleDifuntoChange}
            required
            sx={{ boxShadow: 3 }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth variant="outlined" size="small" sx={{ boxShadow: 3 }}>
            <InputLabel id={difunto.parentesco}>Parentesco</InputLabel>
            <Select
              labelId='Parentesco'
              name="parentesco_difunto"
              value={difunto.parentesco_difunto || ''}
              onChange={handleDifuntoChange}
              label="Parentesco"
            >
              <MenuItem value="" disabled>
                Seleccione una opción
              </MenuItem>
              <MenuItem value="1">Padre/Madre</MenuItem>
              <MenuItem value="2">Hijo(a)</MenuItem>
              <MenuItem value="3">Esposo(a)/Conyugue</MenuItem>
              <MenuItem value="4">Hermano(a)</MenuItem>
              <MenuItem value="5">Abuelo(a)</MenuItem>
              <MenuItem value="6">Tío(a)</MenuItem>
              <MenuItem value="7">Primo(a)</MenuItem>
              <MenuItem value="8">Otro(a)</MenuItem>
            </Select>
          </FormControl>
        </Grid>   
      </Grid>
        
      <Typography
        sx={{ marginTop: '25px', marginBottom: '7px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
      >
        DATOS DE DIFUNCION
      </Typography>  

      <Grid container spacing={3}> 
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha de Defunción"
            variant="outlined"
            type="date"
            size="small"
            name="fecha_defuncion"
            value={difunto.fecha_defuncion || ''}
            onChange={handleDifuntoChange}
            required
            sx={{ boxShadow: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Causa de Defunción"
            variant="outlined"
            size="small"
            name="causa_defuncion"
            value={difunto.causa_defuncion || ''}
            onChange={handleDifuntoChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>    
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Dirección de Defunción"
            variant="outlined"
            size="small"
            name="direccion_defuncion"
            value={difunto.direccion_defuncion || ''}
            onChange={handleDifuntoChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>               
      </Grid>

      {/* RESUMEN DE SERVICIOS FUNERARIOS */}
      <Typography
        sx={{ textAlign: 'center', marginTop: '25px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
        variant="h6"
        gutterBottom
      >
        RESUMEN DE SERVICIOS FUNERARIOS
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: '#0A4575', color: '#FFFFFF' }}>
            <TableRow>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Tipo de Capilla</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Traslado</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Horas Velación</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Precio Velación</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Preparación Difunto</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Alquiler Ataud</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Seleccionar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {funerariosItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.descripcion}</TableCell>
                <TableCell>{mostrarTraslado ? `$${item.traslado}` : '-'}</TableCell>
                <TableCell>{item.velacion}</TableCell>
                <TableCell>{`$${item.precioVelacion}`}</TableCell>
                <TableCell>{mostrarPreparacion ? `$${item.preparacion}` : '-'}</TableCell>
                <TableCell>{mostrarAtaud ? `$${item.alquiler}` : '-'}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={funerariosItemsSeleccionados.includes(index)}
                    onChange={() => handleSeleccionarFunerariosItem(index)}
                    sx={{
                      color: '#0A4575',
                      '&.Mui-checked': {
                        color: '#0A4575',
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}

              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'right' }}>
                  <Typography 
                    component="div" 
                    sx={{ display: 'flex', justifyContent: 'flex-end', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', fontSize: '18px' }}
                  >
                    Total:
                    <Typography component="span" sx={{ paddingLeft: '5px', paddingTop: '3px', fontWeight: 'bold', color: '#000000' }}>
                      ${precioTotalContrato.toFixed(2)}
                    </Typography>
                  </Typography>
                </TableCell>
              </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            marginTop: 3 
          }}
        >
          {/* Botón "Volver" a la izquierda */}
          <Button
            variant="contained"
            component={Link}      // <--- Añadir esto
            to="/ventas"          // <--- Asegúrate de que esta ruta existe en tu App
            sx={{ 
              backgroundColor: '#0A4575', 
              color: '#FFFFFF', 
              '&:hover': {
                backgroundColor: '#083a5e'
              }
            }}
          >
            Volver
          </Button>
          
          {/* Contenedor para los otros botones a la derecha */}
          <Box>
            <Button
              variant="outlined"
              color="error"
              sx={{ 
                backgroundColor: '#0A4575',
                color: '#FFFFFF !important',
                borderColor: '#0A4575',
                '&:hover': {
                  backgroundColor: '#083a5e !important'
                },
                marginRight: 2,
              }}
              onClick={handleEliminarFunerariosItems}
              disabled={funerariosItemsSeleccionados.length === 0}
            >
              Eliminar Ítems
            </Button> 
            
            <Button
              type="submit"
              variant="contained"
              sx={{ 
                backgroundColor: '#0A4575',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#000000'
                }
              }}
            >
              Guardar Solicitud
            </Button>
          </Box>
        </Box>
    </Box>
  );
};

export default ServicioFunerario;
