import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import VentasLayout from './VentasLayout';
import VentasPage from './VentasPage';
import VentasContrato from './Contrato';
import ContratoCremacion from './Cremacion';
import ContratoParcelas from './Parcelas';
import ContratoExequias from './Exequia';
import ContratoInhumacion from './Inhumacion';
import ContratoNichosAtaud from './NichosAtaud';
import ContratoPrevisivos from './Previsivos';
import ContratoColumbarios from './Columbarios';
import ContratoFunerario from './ServicioFunerario';
import ClienteList from './ClienteList';
import ContratoList from './ContratoList';
import SendEmail from './Email';
import Cotizaciones from './Cotizacion';
import Ayuda from '../ayuda/Ayuda';
import AyudaGeneral from '../ayuda/AyudaGeneral';
import fondo from '../../assets/img/fondo.png';
const VentasDashboard = () => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      minHeight: '100vh',            
      backgroundImage: `url(${fondo})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat', }}
    >
      <CssBaseline />

      <Header />

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <VentasLayout />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 2,
            overflow: 'auto',
 
          }}
        >
          <Routes>   
            <Route index element={<VentasPage />} />
            <Route path="contrato/:producto" element={<VentasContrato />} />       
            <Route path="contrato/:producto" element={<ContratoParcelas />} />
            <Route path="contrato/:producto" element={<ContratoCremacion />} />
            <Route path="contrato/:producto" element={<ContratoExequias />} />
            <Route path="contrato/:producto" element={<ContratoInhumacion />} />
            <Route path="contrato/:producto" element={<ContratoNichosAtaud />} />
            <Route path="contrato/previsivo" element={<ContratoPrevisivos />} />
            <Route path="contrato/:producto" element={<ContratoColumbarios />} />
            <Route path="contrato/servicio-funerario" element={<ContratoFunerario />} />
            <Route path="contrato/cotizaciones" element={<Cotizaciones />} />
            <Route path="/estado-clientes" element={<ClienteList />} />
            <Route path="/estado-contratos" element={<ContratoList />} />
            <Route path="/email" element={<SendEmail />} />
            <Route path="ayuda" element={<Ayuda />} />
            <Route path="ayuda/:temaId" element={<AyudaGeneral />} />
          </Routes>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default VentasDashboard;
