// Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { ToastContainer} from 'react-toastify';

const Footer = () => {
  return (
    <>
      <Box
        component="footer"
        sx={{
          py: 2,
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          mt: 'auto',
        }}
      >
        <Typography variant="body2" color="textSecondary">
          © 2024 Cementerio Puertas al Cielo
        </Typography>
      </Box>
      <Box>
        <ToastContainer position="bottom-right" />
      </Box>
    </>
  );
};

export default Footer;
