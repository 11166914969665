// CotizacionList.js
import React from "react";
import {
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Tooltip
} from '@mui/material';

export const FormParcela = () => {
  return (
    <>

    </>  
  );
};

export const FormCremacion = () => {
  return (
    <div>
      <h3>Formulario Cremación</h3>
      {/* Aquí iría el código específico para el formulario de Cremación */}
    </div>
  );
};

// Y así sucesivamente para tus otros 6 formularios:
// export const FormNicho = ...
// export const FormUrna = ...
// etc...
