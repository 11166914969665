import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid, Typography, Box } from '@mui/material';

import {
    FormParcela,
    FormCremacion,
    // FormNicho,
    // FormUrna,
    // ...etc
  } from "./CotizacionList";

const Cotizacion = () => {
    const [planSeleccionado, setPlanSeleccionado] = useState('');
    const [items, setItems] = useState([]);

    const planes = [
        { id: 1, tipo_servicio: 'parcela', descripcion: 'Fosa o Parcela' },
        { id: 2, tipo_servicio: 'nichos-columbarios', descripcion: 'Nicho de Columbario' },
        { id: 3, tipo_servicio: 'nichos-ataud', descripcion: 'Nicho de Ataúd' },
        { id: 4, tipo_servicio: 'cremacion', descripcion: 'Servicio de Cremación' },
        { id: 5, tipo_servicio: 'honra-exequias', descripcion: 'Servicio de Honra de Exequias' },
        { id: 6, tipo_servicio: 'inhumacion', descripcion: 'Servicio de Inhumación' }
    ];

    const handlePlanChange = (e) => {
        setPlanSeleccionado(e.target.value);
    };

    const agregarAlPresupuesto = (item) => {
        setItems((prevItems) => [...prevItems, item]);
    };

    return (
        <>
            <Typography
                sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }}
                variant="h5"
            >
                SOLICITUD DE SERVICIO
            </Typography>
            <Box>
                <Typography
                    sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}
                    variant="h6"
                >
                    PLAN A COTIZAR
                </Typography>
            </Box>

            <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small" variant="outlined" sx={{ boxShadow: 3 }}>
                        <InputLabel>Plan</InputLabel>
                        <Select
                            label="Plan"
                            value={planSeleccionado}
                            onChange={handlePlanChange}
                        >
                            <MenuItem value="" disabled>
                                Selecciona un Plan
                            </MenuItem>
                            {planes.map((plan) => (
                                <MenuItem key={plan.id} value={plan.tipo_servicio}>
                                    {plan.descripcion}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {/* Renderizado condicional */}
            {planSeleccionado === "parcela" && <FormParcela />}
            {planSeleccionado === "cremacion" && <FormCremacion />}
            {/* Agrega las demás condiciones, ejemplo:
                {opcionSeleccionada === "nicho" && <FormNicho />}
                {opcionSeleccionada === "urna" && <FormUrna />}
                etc.
            */}
        </>
    );
};

export default Cotizacion;