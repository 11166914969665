import React, { useEffect, useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
const apiUrl = process.env.REACT_APP_API_URL;

const ClienteList = () => {
  const [clientes, setClientes] = useState([]);
  
  const [detallesContrato, setDetallesContrato] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const parentesco = [
    { id: 1, parentesco: 'Madre/Padre' },
    { id: 2, parentesco: 'Hermano/Hermana' },
    { id: 3, parentesco: 'Esposo/Esposa' },
    { id: 4, parentesco: 'Hijo/Hija' },
    { id: 5, parentesco: 'Nieto/Nieta' },
    { id: 6, parentesco: 'Sobrino/Sobrina' },
    { id: 7, parentesco: 'Tío/Tía' },
    { id: 8, parentesco: 'Otro' },
  ];

  const parentescoMap = {};
    parentesco.forEach((item) => {
      parentescoMap[item.id] = item.parentesco;
  });
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }


  useEffect(() => {
    const obtenerClientes = async () => {
      try {
        // Obtener el token de autorización desde el almacenamiento local
        const token = localStorage.getItem("access_token");
        
        // Configurar los encabezados de la solicitud
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
    
        // Realizar la solicitud GET con el token de autorización
        const response = await axios.get(`${apiUrl}/api/clientes`, config);
        //console.log(response.data); // Procesar la respuesta según tus necesidades
        setClientes(response.data); // Actualizar el estado con los datos obtenidos
    
      } catch (error) {
        //console.error("Error al obtener los clientes:", error);
      }
    };

    obtenerClientes();
  }, []);

  const mostrarDetallesCliente = async (clienteId) => {
    try {
        const token = localStorage.getItem("access_token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        // Obtener contratos del cliente
        const response = await axios.get(`${apiUrl}/api/contratos/cliente/${clienteId}`, config);
        setDetallesContrato(response.data); // Guardar contratos en el estado

        setOpenDialog(true);
    } catch (error) {
        toast.error("Error al obtener los contratos del cliente.");
        setDetallesContrato([]); // Limpiar si no hay contratos
        setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);    
  };

  return (
    <Box component="form"        
      sx={{
        maxWidth: '950px',
        margin: '0 auto',  
        padding: 3,      
        boxShadow: 3,
        backgroundColor: '#ffffff',
        borderRadius: 2,
      }}
    >
      <Typography sx={{ textAlign: 'center', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6" gutterBottom>
        LISTA DE CLIENTES
      </Typography>

      <TableContainer>  
        <Table>  
            <TableHead>  
                <TableRow sx={{ backgroundColor: '#0A4575' }}>  
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Nombre y Apellido</TableCell>  
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Cédula de Identidad</TableCell>    
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Dirección</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Teléfono</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Correo Electrónico</TableCell>  
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Detalles</TableCell>  
                </TableRow>  
            </TableHead>  
            <TableBody>  
            {clientes.map((cliente) => (
              <TableRow key={cliente.id}>  
                  <TableCell>{cliente.nombre}</TableCell>
                  <TableCell>{cliente.cedula}</TableCell>    
                  <TableCell>{cliente.direccion}</TableCell>  
                  <TableCell>{cliente.telefono}</TableCell> 
                  <TableCell>{cliente.email}</TableCell> 
                  <TableCell>
                  <Button variant="contained" color="primary" onClick={() => mostrarDetallesCliente(cliente.id)}>
                      Ver Detalles
                    </Button>
                  </TableCell>
              </TableRow>  
            ))}  
            </TableBody>  
        </Table>  
      </TableContainer>   
      
      {/* Diálogo para mostrar los detalles del cliente */}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}>
          SOLICITUDES DE CONTRATOS
        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#0A4575' }}>
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>No. de Transacción</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Tipo de Servicio</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Modalidad de Pago</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Precio</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Fecha Creación</TableCell>
                  <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detallesContrato.map((detalle) => (
                  <TableRow key={detalle.id}>
                    <TableCell>{detalle.numero_contrato}</TableCell>
                    <TableCell>{detalle.tipo_servicio}</TableCell>
                    <TableCell>{detalle.modalidad_pago}</TableCell>
                    <TableCell>{detalle.precio_total}</TableCell>                    
                    <TableCell>{formatDate(detalle.fecha_creacion)}</TableCell>
                    <TableCell>{detalle.estado}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> 
    </Box>  
  );
};

export default ClienteList;
