// src/pages/Ventas/VentasPage.js
import React from 'react';
import { Box, Typography } from '@mui/material';


const VentasPage = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{marginTop: '15px', marginLeft: '15px', color: '#ffffff'}}>
        Bienvenido al Módulo de Ventas
      </Typography>
      <Typography variant="body1" sx={{ marginLeft: '15px', marginBottom: '1rem', color: '#ffffff' }}>
        Aquí podrás gestionar todos los productos y servicios para las ventas.
      </Typography>
    </Box>
  );
};

export default VentasPage;
