import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import axios from 'axios';
import { TablasEmail } from './EmailTablas';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ListaEmail = () => {    
    const [contratos, setContratos] = useState([]);
    const [detallesContrato, setDetallesContrato] = useState([]);  // Asegúrate de que detallesContrato sea un array
    const [openDialog, setOpenDialog] = useState(false);    
    const [tipoServicio, setTipoServicio] = useState("");
    const [selectedContrato, setSelectedContrato] = useState(null);
    const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
    const [difuntoSeleccionado, setDifunto] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    }

    useEffect(() => {
        const obtenerContratos = async () => {
            try {
                const token = localStorage.getItem("access_token");
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
                const response = await axios.get(`${apiUrl}/api/contratos`, config);
                setContratos(response.data);
            } catch (error) {
                console.error("Error al obtener los contratos:", error);
            }
        };

        obtenerContratos();
    }, [apiUrl]);

    const mostrarDetallesContrato = async (clienteId, contratoId, tipo_servicio) => {
        try {
            const token = localStorage.getItem("access_token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
    
            // Obtener datos del cliente
            const responseCliente = await axios.get(`${apiUrl}/api/clientes/${clienteId}`, config);
            setClienteSeleccionado(responseCliente.data);
    
            // Obtener detalles del contrato
            const responseDetalles = await axios.get(`${apiUrl}/api/detalles_contrato/${contratoId}`, config);
            setDetallesContrato(responseDetalles.data);
    
            // Consultar datos del difunto solo si el servicio lo requiere
            if (tipo_servicio === "Servicio Funerario") {
                try {
                    const responseDifunto = await axios.get(`${apiUrl}/api/difunto/${contratoId}`, config);
                    setDifunto(responseDifunto.data);
                } catch (error) {
                    console.warn("No se encontraron datos del difunto:", error);
                    setDifunto(null); // Limpia los datos del difunto si no se encuentran
                }
            } else {
                setDifunto(null); // Limpia los datos del difunto para otros servicios
            }
    
            // Actualizar otros estados
            setTipoServicio(tipo_servicio);
    
            // Obtener el contrato seleccionado
            const contratoSeleccionado = contratos.find((contrato) => contrato.id === contratoId);
            setSelectedContrato(contratoSeleccionado);
    
            setOpenDialog(true);
        } catch (error) {
            console.error("Error al obtener los detalles del contrato:", error);
            setDetallesContrato([]); // Limpiar si no hay detalles
            setDifunto(null); // Asegúrate de limpiar los datos del difunto en caso de error
            setOpenDialog(true);
        }
    };
    

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDetallesContrato([]);  // Limpiar detalles al cerrar el modal
    };

    const enviarCorreo = async () => {
        try {
            const token = localStorage.getItem("access_token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            // Datos para el correo electrónico
            const correoData = {
                contrato: selectedContrato,
                detalles: detallesContrato,
                cliente: clienteSeleccionado,
                tipoServicio: tipoServicio,
                difunto: difuntoSeleccionado,
                user_email: localStorage.getItem("user_email"),  // Asumiendo que tienes el email del usuario almacenado
            };

            await axios.post(`${apiUrl}/api/enviar_correo`, correoData, config);
            toast.success("Correo enviado exitosamente"); 
            
            //setOpenEmailDialog(false);
        } catch (error) {
            console.error("Error al enviar el correo electrónico:", error);
            alert("Ocurrió un error al enviar el correo electrónico");
        }
    };

    return (
        
        <Box component="form" sx={{ maxWidth: '950px', margin: '0 auto', padding: 3, boxShadow: 3, backgroundColor: '#ffffff', borderRadius: 2 }}>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            
            <Typography sx={{ textAlign: 'center', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6" gutterBottom>
                LISTA DE SOLICITUDES DE CONTRATOS
            </Typography>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#0A4575' }}>
                            <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>No. de Transacción</TableCell>
                            <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Fecha Creación</TableCell>
                            <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Fecha de Vencimiento</TableCell>
                            <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Tipo de Servicio</TableCell>                
                            <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Total</TableCell>                
                            <TableCell sx={{ textAlign: 'center', color: '#FFFFFF', paddingTop: '8px', paddingBottom: '8px', fontSize: '16px' }}>Detalles</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contratos.map((contrato) => (
                            <TableRow key={contrato.id}>
                                <TableCell sx={{ textAlign: 'center'}}>{contrato.numero_contrato}</TableCell>
                                <TableCell sx={{ textAlign: 'center'}}>{formatDate(contrato.fecha_creacion)}</TableCell>
                                <TableCell sx={{ textAlign: 'center'}}>{contrato.fecha_vencimiento ? formatDate(contrato.fecha_vencimiento) : ''}</TableCell>
                                <TableCell sx={{ textAlign: 'center'}}>{contrato.tipo_servicio}</TableCell>                    
                                <TableCell sx={{ textAlign: 'center'}}>{`$${contrato.precio_total}`}</TableCell>                    
                                <TableCell sx={{ textAlign: 'center'}}>
                                    <Button variant="contained" color="primary" onClick={() => mostrarDetallesContrato(contrato.cliente_id, contrato.id, contrato.tipo_servicio)}>
                                        Ver Detalles
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Diálogo para mostrar los detalles del contrato */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle sx={{ textAlign: 'center', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}>
                    DETALLES DE LA SOLICITUD DE CONTRATO
                </DialogTitle>
                <DialogContent>
                    {/* Renderiza la tabla correspondiente según el tipo_servicio */}
                    <TablasEmail tipoServicio={tipoServicio} cliente={clienteSeleccionado} detalles={detallesContrato} difunto={difuntoSeleccionado} />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px'}}>
                            {/* Botón para enviar el correo electrónico */}
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={enviarCorreo}                                
                            >
                                Enviar por Correo Electrónico
                            </Button>
                        </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default ListaEmail;
