import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Columbarios = ({ agregarAlPresupuesto, resetearCampos }) => {
  const [codigo, setCodigo] = useState('');
  const [columbario, setColumbario] = useState({});
  const [modalidadPago, setModalidadPago] = useState('');
  const [inicial, setInicial] = useState(0);
  const [cuotas, setCuotas] = useState(1);
  const [montoCuotas, setMontoCuotas] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;

  // Manejar cambios en el código
  const handleCodigoChange = (e) => {
    setCodigo(e.target.value);
  };

  const parseNumber = (value) => {
    if (!value) return 0;
    // Reemplazar coma por punto y convertir a número
    return parseFloat(value.replace(',', '.'));
  };
  
  // Consultar datos del columbario por código
  const handleCodigo = async () => {
    if (!codigo) return;

    try {
      const response = await axios.get(`${apiUrl}/api/consulta-columbario/${codigo}`);
      const data = response.data;

      if (data.estado.toLowerCase() === 'ocupado') {
        toast.error('El nicho seleccionado está ocupado. Por favor, elija otro.');
        setColumbario({});  // Limpiar el estado del columbario
        return;
      }

      setColumbario({
        ...data,
        plan: data.ubicacion || '',
        mto_contado: parseNumber(data.mto_contado),
        mto_financiado: parseNumber(data.mto_financiado),
      });
    } catch (error) {
      console.error('Error al consultar los datos:', error);
      toast.error('No se encontraron datos para el código ingresado.');
    }
  };

  // Calcular monto por cuota
  useEffect(() => {
    if (modalidadPago === 'financiado' && !isNaN(parseFloat(columbario.mto_financiado))) {
      const totalFinanciar = parseFloat(columbario.mto_financiado) - inicial;
      setMontoCuotas(totalFinanciar > 0 ? totalFinanciar / cuotas : 0);
    } else {
      setMontoCuotas(0);
    }
  }, [modalidadPago, inicial, cuotas, columbario.mto_financiado]);  

  // Manejar la acción de agregar al contrato
  const handleAgregar = () => {
    if (!modalidadPago) return;

    const item = {
      codigo: codigo,
      descripcion: `Contrato del plan ${columbario.plan}`,
      tipo_servicio: 'Nicho de Columbario',
      modalidadPago: modalidadPago === 'financiado' ? 'Financiado' : 'De Contado',
      precioTotal: modalidadPago === 'financiado' ? columbario.mto_financiado : columbario.mto_contado,
      pagoInicial: inicial,
      cuotas: modalidadPago === 'financiado' ? cuotas : '',
      montoPorCuota: modalidadPago === 'financiado' ? montoCuotas.toFixed(2) : '',
    };

    agregarAlPresupuesto(item);
  };

  useEffect(() => {
    if (resetearCampos) {
      resetFormulario();
    }
  }, [resetearCampos]);

  const resetFormulario = () => {
    setCodigo('');
    setColumbario({});
    setModalidadPago('');
    setInicial(0);
    setCuotas(1);
    setMontoCuotas(0);
  };
  
  return (
    <>
      <Typography sx={{ marginBottom: '5px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575', textAlign: 'center' }} variant="h5">
        SOLICITIUD DE CONTRATO NICHOS DE COLUMBARIOS
      </Typography>
      <Box>
        <Typography sx={{ marginTop: '15px', marginBottom: '10px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
          PLAN A SOLICITAR
        </Typography>
      </Box>

      {/* Ubicación */}
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            label="Código"
            variant="outlined"
            size="small"
            name="codigo_columbario"            
            value={codigo || ''}
            onChange={handleCodigoChange}
            onBlur={handleCodigo}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            label="Capilla"
            variant="outlined"
            size="small"
            value={columbario.capilla || ''}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            label="lado"
            variant="outlined"
            size="small"
            name="lado"            
            value={columbario.lado || ''}
            required
            InputLabelProps={{ shrink: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            label="cantidad de nichos"
            variant="outlined"
            size="small"
            name="cantidad_nichos"            
            value={columbario.cantidad_nichos || ''}
            required
            InputLabelProps={{ shrink: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            label="fila"
            variant="outlined"
            size="small"
            name="fila"            
            value={columbario.fila || ''}
            required
            InputLabelProps={{ shrink: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            label="columna"
            variant="outlined"
            size="small"
            name="columna"            
            value={columbario.columna || ''}
            required
            InputLabelProps={{ shrink: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>     
        <Grid item xs={5}>
          <TextField
            fullWidth
            label="Plan"
            variant="outlined"
            size="small"
            value={columbario.plan || ''}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
            sx={{ boxShadow: 3 }}
          />
        </Grid>    
      
        {/* Modalidad */}
        <Grid item xs={2}>
          <FormControl fullWidth variant="outlined" size="small" sx={{ boxShadow: 3 }}>
            <InputLabel id="modalidad de Pago">Modalidad de Pago</InputLabel>
            <Select
              labelId="modalidad de Pago"
              name="modalidad"
              value={modalidadPago}
              onChange={(e) => setModalidadPago(e.target.value)}
              label="Modalidad de Pago"
            >
              <MenuItem value="" disabled>Seleccione una opción</MenuItem>
              <MenuItem value="contado">De Contado</MenuItem>
              <MenuItem value="financiado">Financiado</MenuItem>
            </Select>
          </FormControl>
        </Grid>


        {/* Inputs para la modalidad de "Contado" */}
        {modalidadPago === 'contado' && (
          <>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Monto Contado"
                variant="outlined"
                size="small"
                value={columbario.mto_contado !== undefined ? columbario.mto_contado.toFixed(2) : '0.00'}
                InputProps={{ readOnly: true }}
                sx={{ boxShadow: 3 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAgregar}
                disabled={!modalidadPago}
                fullWidth
              >
                Agregar Solicitud
              </Button>
            </Grid>
          </>
        )}

        {/* Inputs para la modalidad de "Financiado" */}
        {modalidadPago === 'financiado' && (
          <>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Monto Financiado"
                variant="outlined"
                size="small"
                value={columbario.mto_financiado ? `$${columbario.mto_financiado.toFixed(2)}` : ''}
                InputProps={{ readOnly: true }}
                sx={{ boxShadow: 3 }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Monto Inicial ($)"
                variant="outlined"
                size="small"
                type="text"
                value={inicial}
                onChange={(e) => setInicial(parseFloat(e.target.value) || 0)}
                sx={{ boxShadow: 3 }}
              />
            </Grid>

            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Cuotas"
                variant="outlined"
                size="small"
                type="number"
                value={cuotas}
                onChange={(e) => {
                  const value = parseInt(e.target.value) || 1;
                  if (value >= 1 && value <= 24) {
                    setCuotas(value);  // Solo actualiza el estado si está dentro del rango permitido
                  }
                }}
                sx={{ boxShadow: 3 }}
                inputProps={{ min: 1, max: 24 }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="Monto por Cuota"
                variant="outlined"
                size="small"
                type="number"
                value={montoCuotas > 0 ? montoCuotas.toFixed(2) : '0.00'}
                InputProps={{ readOnly: true }}
                sx={{ boxShadow: 3 }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAgregar}
                disabled={!modalidadPago}
                fullWidth
              >
                Agregar Solicitud
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Columbarios;