
import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const Ayuda = () => {
  
  return (
    <Box
      sx={{
        // Ajusta alto, fondo, etc.
        minHeight: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#FFFFFF',
        padding: '40px'
      }}
    >
      {/* Encabezados */}
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
        Preguntas Frecuentes
      </Typography>

      {/* Lista vertical de servicios */}
      <Box sx={{ marginTop: '30px', display: 'flex', flexDirection: 'column', gap: 1 }}>
        
        {/* Ejemplo con Link de React Router */}
        <Box
          component={Link} 
          to="/ventas/ayuda/cremacion" 
          sx={{
            textDecoration: 'none',
            color: '#FFFFFF',
            fontSize: '18px',
            fontFamily: 'Bakbak One, sans-serif',
            transition: 'all 0.3s ease',
            transform: 'scale(1)',
            '&:hover': {
              color: 'yellow',
              transform: 'scale(1.05)',
            },
          }}
        >
          - Cremación
        </Box>

        <Box
          component={Link} 
          to="/ventas/ayuda/exequias"
          sx={{
            textDecoration: 'none',
            color: '#FFFFFF',
            fontSize: '18px',
            fontFamily: 'Bakbak One, sans-serif',
            transition: 'all 0.3s ease',
            transform: 'scale(1)',
            '&:hover': {
              color: 'yellow',
              transform: 'scale(1.05)',
            },
          }}
        >
          - Honra de Exequias
        </Box>
        
        <Box
          component={Link} 
          to="/ventas/ayuda/funerario"
          sx={{
            textDecoration: 'none',
            color: '#FFFFFF',
            fontSize: '18px',
            fontFamily: 'Bakbak One, sans-serif',
            transition: 'all 0.3s ease',
            transform: 'scale(1)',
            '&:hover': {
              color: 'yellow',
              transform: 'scale(1.05)',
            },
          }}
        >
          - Servicios Funerarios
        </Box>

        <Box
          component={Link} 
          to="/ventas/ayuda/parcelas"
          sx={{
            textDecoration: 'none',
            color: '#FFFFFF',
            fontSize: '18px',
            fontFamily: 'Bakbak One, sans-serif',
            transition: 'all 0.3s ease',
            transform: 'scale(1)',
            '&:hover': {
              color: 'yellow',
              transform: 'scale(1.05)',
            },
          }}
        >
          - Parcelas
        </Box>

        <Box
          component={Link} 
          to="/ventas/ayuda/columbarios"
          sx={{
            textDecoration: 'none',
            color: '#FFFFFF',
            fontSize: '18px',
            fontFamily: 'Bakbak One, sans-serif',
            transition: 'all 0.3s ease',
            transform: 'scale(1)',
            '&:hover': {
              color: 'yellow',
              transform: 'scale(1.05)',
            },
          }}
        >
          - Columbarios
        </Box>

      </Box>
    </Box>
  );
};

export default Ayuda;