import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AYUDA_DATA, AYUDA_KEYS } from './dataAyuda';

const AyudaGeneral = () => {
  const { temaId } = useParams();
  const navigate = useNavigate();

  // Hallamos el índice inicial
  const currentIndexInitial = AYUDA_KEYS.indexOf(temaId);

  // Mantenemos un estado para el índice
  const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect se llama SIEMPRE; luego dentro vemos si es válido
  useEffect(() => {
    if (currentIndexInitial >= 0) {
      setCurrentIndex(currentIndexInitial);
    }
  }, [currentIndexInitial]);

  // Si el temaId no está en AYUDA_KEYS => sección no encontrada
  const isInvalid = currentIndexInitial < 0;

  // Funciones de siguiente/anterior
  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % AYUDA_KEYS.length;
    const nextKey = AYUDA_KEYS[nextIndex];
    navigate(`/ventas/ayuda/${nextKey}`);
  };

  const handlePrev = () => {
    const prevIndex =
      currentIndex === 0 ? AYUDA_KEYS.length - 1 : currentIndex - 1;
    const prevKey = AYUDA_KEYS[prevIndex];
    navigate(`/ventas/ayuda/${prevKey}`);
  };

  // Ahora sí, hacemos el return condicional
  if (isInvalid) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" color="error">
          Sección no encontrada
        </Typography>
      </Box>
    );
  }

  const currentKey = AYUDA_KEYS[currentIndex];
  const currentTema = AYUDA_DATA[currentKey];

  return (
    <>
      {/* HEADER */}
      <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#0A4575',
        color: '#fff',
        padding: '8px 16px',
        marginBottom: '20px'
      }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Preguntas Frecuentes
      </Typography>
    
      <Button
        variant="contained"
        component={Link}      // <--- Añadir esto
        to="/ventas"          // <--- Asegúrate de que esta ruta existe en tu App
        sx={{ 
          backgroundColor: '#0A4575', 
          color: '#FFFFFF', 
          '&:hover': {
            backgroundColor: '#083a5e'
          }
        }}
      >
        Volver
      </Button>
    </Box>
      

      <Paper
        elevation={3}
        sx={{
          padding: 2,
        }}
      >
        {/* ENCABEZADO Y FLECHAS */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IconButton onClick={handlePrev} color="primary">
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h5" align="center" sx={{ fontWeight: 'bold' }}>
            {currentTema.titulo}
          </Typography>
          <IconButton onClick={handleNext} color="primary">
            <ArrowForwardIos />
          </IconButton>
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* PROCESO */}
        <Box sx={{ marginLeft: '10px', marginRight: '10px'}}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            <b>Proceso: </b>{currentTema.proceso.nombre}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: 'Justify' }}>
            <strong>Definición:</strong> {currentTema.proceso.definicion}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            <strong>Características:</strong> {currentTema.proceso.caracteristicas}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: 'Justify' }}>
            <strong>Insumos:</strong> {currentTema.proceso.insumos}
          </Typography>
          <Typography variant="body1" sx={{ mb: 2, textAlign: 'Justify' }}>
            <strong>Servicios Extras:</strong> {currentTema.proceso.servicios_extras}
          </Typography>
          {/* ETAPAS */}
          <Typography variant="subtitle1" sx={{ color: '#0A4575', fontFamily: 'BakBak One', mb: 1, fontSize: '18px'}}>
          ETAPAS - PROCESO
          </Typography>
        </Box>

        <List dense>
          {currentTema.etapas.map((etapa, idx) => (
            <ListItem key={idx}>                
                <Typography variant="body1">{etapa}</Typography>                
              <ListItemText />
            </ListItem>
          ))}
        </List>

        {/* PREGUNTAS Y RESPUESTAS */}
        <Typography
          variant="subtitle1"
          sx={{ marginLeft: '10px', fontWeight: 'bold', mb: 1, mt: 2 }}
        >
          Preguntas y Respuestas
        </Typography>
        {currentTema.preguntas.map((item, idx) => (
          <Box key={idx} sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ marginLeft: '10px', fontWeight: 'bold' }}>
              {item.pregunta}
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: '10px', }}>
              {item.respuesta}
            </Typography>
          </Box>
        ))}
      </Paper>
        
  </>  
  );
};

export default AyudaGeneral;
