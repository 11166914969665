import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Checkbox,
  TextField
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Cremacion from './Cremacion';
import Exequia from './Exequia';
import Inhumacion from './Inhumacion';
import Parcelas from './Parcelas';
import NichosColumbarios from './Columbarios';
import NichosAtaud from './NichosAtaud';
import Cotizaciones from './Cotizacion';

const VentasContrato = () => {
  const [contratoId, setContratoId] = useState();
  const { producto } = useParams();  
  const [items, setItems] = useState([]); // Aquí se guardarán los productos añadidos 
  const [itemsSeleccionados, setItemsSeleccionados] = useState([]); // Para eliminar los ítems seleccionados
  const [totalContrato, setTotalContrato] = useState(0);
  const [modalidadPago, setModalidadPago] = useState(null);
  const [selectCliente, setSelectCliente] = useState("");
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [resetCampos, setResetCampos] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [cliente, setCliente] = useState({
    nombre: '',
    cedula: '',
    fecha_nacimiento: '',
    edad: '',
    telefono: '',
    celular: '',
    email: '',
    direccion: '',
    nombre_documento: '',
    documento_identidad: null,       
  });

  const buscarCliente = async (cedula) => {
    if (!cedula.trim()) return; // Evitar búsquedas con campos vacíos

    try {
        const token = localStorage.getItem("access_token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await axios.get(`${apiUrl}/api/cliente/${cedula}`, config);

        // Si se encuentra el cliente, actualizar los datos automáticamente
        const clienteEncontrado = response.data;
        toast.success("El cliente ya está registrado. Se asociará el contrato.");

        // Convertir fecha_nacimiento al formato yyyy-MM-dd
        const fechaFormateada = clienteEncontrado.fecha_nacimiento
            ? new Date(clienteEncontrado.fecha_nacimiento).toISOString().split('T')[0]
            : '';

        setClienteSeleccionado(clienteEncontrado); // Establece el cliente seleccionado
        setCliente({
            nombre: clienteEncontrado.nombre,
            cedula: clienteEncontrado.cedula,
            fecha_nacimiento: fechaFormateada, // Fecha en formato yyyy-MM-dd
            edad: clienteEncontrado.edad,
            telefono: clienteEncontrado.telefono,
            celular: clienteEncontrado.celular,
            email: clienteEncontrado.email,
            direccion: clienteEncontrado.direccion,
            documento_identidad: null, // Ignora este campo si no es relevante
            nombre_documento: '',
        });
        //setSelectCliente("existente"); // Cambia el modo automáticamente a Cliente Existente
    } catch (error) {
        if (error.response?.status === 404) {
            toast.info("Cliente nuevo. Se creará un contrato y cliente nuevo. ");
        } else {
            console.error("Error al buscar cliente:", error);
            toast.error("Error al buscar cliente.");
        }
    }
  };

  // Actualiza los datos del cliente
  const handleClienteChange = (e) => {
    const { name, value } = e.target;
    let updatedFields = { [name]: value };
  
    if (name === 'fecha_nacimiento') {
      const edadCalculada = calcularEdad(value);
      updatedFields.edad = edadCalculada.toString();
    }
  
    setCliente((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  };  

  const agregarAlPresupuesto = (item) => {
    setItems((prevItems) => [...prevItems, item]);
  };

  // Renderiza el formulario adecuado basado en el producto seleccionado
  const renderFormulario = () => {
    switch (producto) {
      case 'cremacion':
        return <Cremacion agregarAlPresupuesto={agregarAlPresupuesto} />;
      case 'honra-exequias':
        return <Exequia agregarAlPresupuesto={agregarAlPresupuesto} />;
      case 'inhumacion-parcelas':
        return <Inhumacion agregarAlPresupuesto={agregarAlPresupuesto} />;      
      case 'parcelas':
        return <Parcelas agregarAlPresupuesto={agregarAlPresupuesto} />;
      case 'nichos-ataud':
        return <NichosAtaud agregarAlPresupuesto={agregarAlPresupuesto} />;
        case 'nichos-columbarios':
          return <NichosColumbarios agregarAlPresupuesto={agregarAlPresupuesto} resetearCampos={resetCampos} />; 
        case 'cotizacion':
          return <Cotizaciones agregarAlPresupuesto={agregarAlPresupuesto} />;                  
      default:
        return <div>Seleccione un producto o servicio</div>;
    }
  }; 

  // Funciones de eliminación separadas
  const handleEliminarItems = () => {
    const nuevosItems = items.filter((_, index) => !itemsSeleccionados.includes(index));
    setItems(nuevosItems);
    setItemsSeleccionados([]); // Reiniciar los ítems seleccionados
  };

  // Manejar la selección de los ítems para eliminar
  const handleSeleccionarItem = (index) => {
    const seleccionado = itemsSeleccionados.includes(index);
    if (seleccionado) {
      setItemsSeleccionados(itemsSeleccionados.filter((i) => i !== index));
    } else {
      setItemsSeleccionados([...itemsSeleccionados, index]);
    }
  };

  // Calcula la edad a partir de la fecha de nacimiento
  const calcularEdad = (fechaNacimiento) => {
    const hoy = new Date();
    const fecha_nacimiento = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - fecha_nacimiento.getFullYear();
    const mes = hoy.getMonth() - fecha_nacimiento.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < fecha_nacimiento.getDate())) {
      edad--;
    }

    return edad;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCliente((prev) => ({
        ...prev,
        documento_identidad: file, // Cambiar para almacenar el archivo, no solo el nombre
        nombre_documento: file.name,
      }));
    }
  };

  useEffect(() => {
    const total = items.reduce((sum, item) => sum + (item.precioTotal || 0), 0);
  
    // Asegúrate de que haya al menos un item y toma la modalidad del primer elemento.
    const modalidadPago = items.length > 0 ? items[0].modalidadPago : null;
  
    setTotalContrato(total);
    setModalidadPago(modalidadPago); // Si tienes una función para guardar la modalidad
  }, [items]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");
    let clienteId;

    try {
        if (clienteSeleccionado) {
            // Cliente existente: usar su ID directamente
            clienteId = clienteSeleccionado.id;
        } else {
            // Validar que todos los campos del cliente nuevo estén completos
            if (!cliente.nombre || !cliente.cedula || !cliente.fecha_nacimiento || !cliente.telefono || !cliente.email || !cliente.direccion) {
                toast.error("Por favor, complete todos los campos obligatorios del cliente.");
                return;
            }

            // Preparar los datos del cliente
            const formData = new FormData();
            Object.keys(cliente).forEach((key) => {
                if (key === "documento_identidad" && cliente[key] instanceof File) {
                    formData.append(key, cliente[key], cliente[key].name);
                } else {
                    formData.append(key, cliente[key]);
                }
            });

            // Crear cliente nuevo
            const clienteResponse = await axios.post(`${apiUrl}/api/clientes`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            clienteId = clienteResponse.data.id; // Obtener el ID del cliente recién creado
        }

        // Registrar el contrato con el clienteId
        const contratoData = {
            cliente_id: clienteId,
            vendedor_id: parseInt(localStorage.getItem("vendedor_id")),
            tipo_servicio: producto,
            modalidad_pago: modalidadPago,
            precio_total: totalContrato,
            estado: "pre-contrato",
            fecha_vencimiento: new Date().toISOString(),
            fecha_actual: new Date().toISOString(),
            descripcion: `Contrato para ${producto}`,
            detalles: items.map((item) => ({
                tipo_contrato: producto,
                descripcion: item.descripcion,
                precio: item.precioTotal,
                codigo_nicho: item.codigo,
                modalidadPago: item.modalidadPago,
                pagoInicial: item.pagoInicial ? parseFloat(item.pagoInicial) : null,
                cuotas: item.cuotas ? parseInt(item.cuotas) : null,
                montoPorCuota: item.montoPorCuota ? parseFloat(item.montoPorCuota) : null,
                precioTotal: parseFloat(item.precioTotal) || 0,
            })),
        };

        await axios.post(`${apiUrl}/api/contratos`, contratoData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });

        const codigoNicho = {
          detalles: items.map((item) => ({
            codigo_nicho: item.codigo,
            estado: "ocupado"
          })),
        };

        await axios.post(`${apiUrl}/api/update_codigo_nicho`, codigoNicho, {
          headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
          },
        });

        toast.success("Contrato registrado con éxito.");
        // Restablecer estados
        setResetCampos(!resetCampos);

        setCliente({
            nombre: "",
            cedula: "",
            fecha_nacimiento: "",
            edad: "",
            telefono: "",
            celular: "",
            email: "",
            direccion: "",
            documento_identidad: null,
            familiares: [],
        });
        setItems([]);
        setClienteSeleccionado(null);
    } catch (error) {
        console.error("Error:", error.response?.data?.detail || error.message);
        toast.error(`Error al registrar contrato: ${error.response?.data?.detail || error.message}`);
    }
};

  return (
    <Box component="form" 
      onSubmit={handleSubmit}       
      sx={{
        maxWidth: '950px',
        margin: '0 auto',  
        padding: 3,      
        boxShadow: 3,
        backgroundColor: '#ffffff',
        borderRadius: 2,
      }}
    >
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    /> 

      {renderFormulario()} 
      
      {/* DATOS DEL CLIENTE */}
      <Typography
        sx={{ marginTop: '20px', marginBottom: '5px', fontFamily: 'Bakbak One', color: '#0A4575' }}
        variant="h6"
      >
        DATOS DEL CLIENTE
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Nombre cliente"
            variant="outlined"
            size="small"
            name="nombre"
            value={cliente.nombre}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Cédula o Rif"
            variant="outlined"
            size="small"
            name="cedula"
            value={cliente.cedula}
            onChange={handleClienteChange}
            onBlur={(e) => buscarCliente(e.target.value)}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Fecha de Nacimiento"
            variant="outlined"
            type="date"
            size="small"
            name="fecha_nacimiento"
            value={cliente.fecha_nacimiento || ''}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            label="Edad"
            variant="outlined"
            size="small"
            name="edad"
            value={cliente.edad}
            required
            sx={{ boxShadow: 3 }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Teléfono"
            variant="outlined"
            size="small"
            name="telefono"
            value={cliente.telefono}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Celular"
            variant="outlined"
            size="small"
            name="celular"
            value={cliente.celular}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            size="small"
            name="email"
            value={cliente.email}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            fullWidth
            label="Dirección"
            variant="outlined"
            size="small"
            name="direccion"
            value={cliente.direccion}
            onChange={handleClienteChange}
            required
            sx={{ boxShadow: 3 }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="file-upload"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              sx={{
                backgroundColor: '#0A4575',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#083a5e',
                },
              }}
            >
              Subir Documento de Identidad
            </Button>
          </label>
        </Grid>
        <Grid item xs={12} sm={2}>
          {cliente.nombre_documento && (
            <Typography sx={{ marginTop: '10px', fontFamily: 'Abel' }}>
              <b>{cliente.nombre_documento}</b>
            </Typography>
          )}
        </Grid>
      </Grid>         

      <Typography sx={{ textAlign: 'center', marginTop: '25px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6" gutterBottom>
        RESUMEN DE SOLICITUD DEL CONTRATO
      </Typography>

      {/* Tabla de Items */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: '#0A4575', color: '#FFFFFF' }}>
            <TableRow>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px', width: '110px' }}>Código</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Descripción</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Modalidad</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Inicial</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Cuotas</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px', width: '110px' }}>Monto Cuota</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Total</TableCell>
              <TableCell sx={{ color: '#FFFFFF', fontSize: '16px' }}>Seleccionar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.codigo}</TableCell>
                <TableCell>{item.descripcion}</TableCell>
                <TableCell>{item.modalidadPago}</TableCell>
                <TableCell>{item.pagoInicial !== '' ? `$${item.pagoInicial}` : ''}</TableCell>
                <TableCell>{item.cuotas}</TableCell>
                <TableCell>{item.montoPorCuota !== '' ? `$${item.montoPorCuota}` : ''}</TableCell>
                <TableCell>{`$${item.precioTotal}`}</TableCell>
                <TableCell>
                  <Checkbox checked={itemsSeleccionados.includes(index)} onChange={() => handleSeleccionarItem(index)} sx={{ color: '#0A4575', '&.Mui-checked': { color: '#0A4575' } }} />
                </TableCell>
              </TableRow>
            ))}

            {/* Fila de Total */}
            <TableRow>
              <TableCell colSpan={6} align="right" >
                <Typography sx={{ color: '#0A4575', fontWeight: 'bold', fontSize: '16px' }}>Total:</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography sx={{ fontWeight: 'bold', color: '#000000' }}>${totalContrato}</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          marginTop: 3 
        }}
      >
        {/* Botón "Volver" a la izquierda */}
        <Button
          variant="contained"
          component={Link}      // <--- Añadir esto
          to="/ventas"          // <--- Asegúrate de que esta ruta existe en tu App
          sx={{ 
            backgroundColor: '#0A4575', 
            color: '#FFFFFF', 
            '&:hover': {
              backgroundColor: '#083a5e'
            }
          }}
        >
          Volver
        </Button>
        
        {/* Contenedor para los otros botones a la derecha */}
        <Box>
          <Button
            variant="outlined"
            color="error"
            sx={{ 
              backgroundColor: '#0A4575',
              color: '#FFFFFF !important',
              borderColor: '#0A4575',
              '&:hover': {
                backgroundColor: '#083a5e !important'
              },
              marginRight: 2,
            }}
            onClick={handleEliminarItems}
            disabled={itemsSeleccionados.length === 0}
          >
            Eliminar Ítems
          </Button> 
          
          <Button
            type="submit"
            variant="contained"
            sx={{ 
              backgroundColor: '#0A4575',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#000000'
              }
            }}            
          >
            Guardar Solicitud
          </Button>
        </Box>
      </Box>
    </Box>                
      
  );
};

export default VentasContrato;
