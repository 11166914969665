import React, { useState } from 'react';
import { jwtDecode } from "jwt-decode";
import { TextField, Button, Box, CircularProgress, Typography, Toolbar, AppBar } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import '../App.css'; 
import Footer from '../components/Footer';   


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const loginData = new URLSearchParams();
    loginData.append('username', username);
    loginData.append('password', password);

    try {
      const response = await axios.post(`${apiUrl}/login`, loginData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const data = response.data;
      
      if (!data.access_token) {
        throw new Error('No se recibió el token.');
      }
      
      const token = data.access_token;
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));

      localStorage.setItem('access_token', token);

      const decoded = jwtDecode(token);

      //console.log(decoded)
      
        if (decoded.vendedor_id) {
            localStorage.setItem("vendedor_id", decoded.vendedor_id);
            localStorage.setItem("user_email", decoded.sub);
            localStorage.setItem("usuario", decoded.usuario);
        }
        
      toast.success('Login exitoso!');

      setTimeout(() => {
        const userRole = tokenPayload.rol;

        switch (userRole) {
          case 'superuser':
            window.location.href = '/admin-panel';
            break;
          case 'asistente':      
            window.location.href = '/Administracion';
            break;
          case 'vendedor':
            window.location.href = '/ventas';
            break;          
          default:
            window.location.href = '/Clientes';
            break;
        }
      }, 3000);

      
    } catch (error) {
      const errorMessage = error.response?.data?.detail || 'Error en el inicio de sesión.';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* AppBar permanece fuera del fondo para no afectar su apariencia */}
      <AppBar position="static" sx={{ backgroundColor: '#0A4575' }}>
        <Toolbar>
          {/* Logo en el lado izquierdo */}
          <img
            src="https://www.cementeriopuertasalcielo.com.ve/wp-content/uploads/2023/02/Logo2x-03-1.webp"
            alt="Logo"
            style={{ width: '270px', marginTop: '15px', marginBottom: '15px', marginRight: '20px' }}
          />
          <Box sx={{ flexGrow: 1 }} /> {/* Empuja los botones hacia la derecha */}
        </Toolbar>
      </AppBar>

      {/* Contenedor principal con la imagen de fondo */}
      <Box
        sx={{
          backgroundImage: `url('https://www.cementeriopuertasalcielo.com.ve/wp-content/uploads/2024/01/CW16-scaled.jpg')`, // Reemplaza con la URL de tu imagen de fondo
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: 'calc(100vh - 80px)', // Restamos la altura del AppBar
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Contenedor del formulario de login */}
        <Box sx={{ 
          display: 'flex', 
          boxShadow: 3, 
          maxWidth: '600px', 
          width: '100%', 
          backgroundColor: '#fff' 
        }}>
          <Box sx={{ backgroundColor: '#0A4575', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2, width: '270px' }}>
            <img src="https://www.cementeriopuertasalcielo.com.ve/wp-content/uploads/2023/02/LogoFooter-1.webp" alt="Logo del Sistema" width="70%" />
          </Box>

          <Box sx={{ marginTop: '20px', marginBottom: '20px', padding: 3, flex: 1 }}>
            <Typography variant="h5" component="h1" align="center" gutterBottom>
              Inicio de Sesión
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <TextField
                fullWidth
                label="Usuario"
                variant="outlined"
                margin="normal"
                size="small"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                sx={{ boxShadow: 3 }}
              />
              <TextField
                fullWidth
                label="Contraseña"
                variant="outlined"
                margin="normal"
                type="password"
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                sx={{ boxShadow: 3 }}
              />

              <Box sx={{ textAlign: 'center', marginTop: 3 }}>
                <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                  {loading ? 'Cargando...' : 'Iniciar Sesión'}
                </Button>
              </Box>
            </Box>

            {loading && (
              <Box sx={{ textAlign: 'center', marginTop: 3 }}>
                <CircularProgress />
              </Box>
            )}
            
          </Box>
        </Box>
      </Box>

      {/* Footer siempre fijo en la parte inferior */}
      <Box sx={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Footer />
      </Box>
    </>
  );
};

export default Login;
