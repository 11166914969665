import React, { useState } from 'react';
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Box,
  Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

const VentasLayout = () => {
  const location = useLocation();
  const [openProductos, setOpenProductos] = useState(false);
  const [openServicios, setOpenServicios] = useState(false);
  const [openVentas, setOpenVentas] = useState(false);
  const [openCotizacion, setOpenCotizacion] = useState(false);
  const [openAyuda, setOpenAyuda] = useState(false);

  const handleProductosClick = () => setOpenProductos(!openProductos);
  const handleServiciosClick = () => setOpenServicios(!openServicios);
  const handleVentasClick = () => setOpenVentas(!openVentas);
  const handleCotizacionClick = () => setOpenCotizacion(!openCotizacion);
  const handleAyudaClick = () => setOpenAyuda(!openAyuda);

  return (
    <Box sx={{ marginLeft: '15px', marginTop: '15px',marginBottom: '15px', width: '100%', maxWidth: 350, bgcolor: 'background.paper', borderTopLeftRadius: '8px', borderBottomRightRadius: '8px', }}>
      <List component="nav">

      <Box>
        <Typography sx={{ marginTop: '15px', marginBottom: '10px', textAlign: 'center', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }} variant="h6">
          MENU
        </Typography>
      </Box>
        
        {/* Productos */}
        <ListItemButton onClick={handleProductosClick}>
        <ListItemText primary="Productos" primaryTypographyProps={{ sx: { fontSize: '20px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}}
        />
          <ListItemText primary="" sx={{ fontWeight: 'bold', color: '#0A4575' }} />
          {openProductos ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>  
        <Collapse in={openProductos} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <ListItemButton 
              component={Link} 
              to="/ventas/contrato/parcelas" 
              selected={location.pathname === '/ventas/contrato/parcelas'}
              sx={{ 
                pl: 4, 
                bgcolor: location.pathname === '/ventas/contrato/parcelas' ? '#0A4575' : 'transparent',
                color: location.pathname === '/ventas/contrato/parcelas' ? '#FFFFFF' : 'inherit',
                '&:hover': {
                  bgcolor: '#e2eef7',
                  color: '#000000',
                },
                '&.Mui-selected': {
                  bgcolor: '#0A4575 !important',
                  color: '#FFFFFF',
                }
              }}
            >
              <ListItemText primary="Solicitud de Contrato de Parcelas" />
            </ListItemButton>

            <ListItemButton 
            component={Link} 
            to="/ventas/contrato/nichos-columbarios" 
            selected={location.pathname === '/ventas/contrato/nichos-columbarios'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/contrato/nichos-columbarios' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/contrato/nichos-columbarios' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Solicitud de Contrato de Nichos de Columbarios" />
          </ListItemButton>  

          <ListItemButton 
            component={Link} 
            to="/ventas/contrato/nichos-ataud" 
            selected={location.pathname === '/ventas/contrato/nichos-ataud'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/contrato/nichos-ataud' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/contrato/nichos-ataud' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Solicitud de Contrato de Nicho de Ataud" />
          </ListItemButton>              

          </List>
        </Collapse>

        {/* Servicios */}
        <ListItemButton onClick={handleServiciosClick}>
          <ListItemText primary="Servicios" primaryTypographyProps={{ sx: { fontSize: '20px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}}
        />
          {openServicios ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openServicios} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

          <ListItemButton 
            component={Link} 
            to="/ventas/contrato/previsivo" 
            selected={location.pathname === '/ventas/contrato/previsivo'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/contrato/previsivo' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/contrato/previsivo' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Solicitud de Contrato de Servicios Previsivo" />
          </ListItemButton>

          <ListItemButton 
            component={Link} 
            to="/ventas/contrato/cremacion" 
            selected={location.pathname === '/ventas/contrato/cremacion'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/contrato/cremacion' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/contrato/cremacion' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Solicitud de Contrato de Cremación" />
          </ListItemButton>
       
          <ListItemButton 
            component={Link} 
            to="/ventas/contrato/honra-exequias" 
            selected={location.pathname === '/ventas/contrato/honra-exequias'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/contrato/honra-exequias' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/contrato/honra-exequias' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Solicitud de Contrato de Honra de Exequias" />
          </ListItemButton>
          
          <ListItemButton 
            component={Link} 
            to="/ventas/contrato/inhumacion-parcelas" 
            selected={location.pathname === '/ventas/contrato/inhumacion-parcelas'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/contrato/inhumacion-parcelas' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/contrato/inhumacion-parcelas' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Solicitud de Contrato de Inhumación de Parcelas" />
          </ListItemButton>

          <ListItemButton 
            component={Link} 
            to="/ventas/contrato/servicio-funerario" 
            selected={location.pathname === '/ventas/contrato/servicio-funerario'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/contrato/servicio-funerario' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/contrato/servicio-funerario' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Solicitud de Contrato de Servicio funerario" />
          </ListItemButton>


          </List>
        </Collapse>

        {/* Ventas */}
        <ListItemButton onClick={handleVentasClick}>
          <ListItemText primary="Ventas" primaryTypographyProps={{ sx: { fontSize: '20px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}}
        />
          {openVentas ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openVentas} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

          <ListItemButton 
            component={Link} 
            to="/ventas/estado-clientes" 
            selected={location.pathname === '/ventas/estado-clientes'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/estado-clientes' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/estado-clientes' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Clientes" />
          </ListItemButton>

          <ListItemButton 
            component={Link} 
            to="/ventas/estado-contratos" 
            selected={location.pathname === '/ventas/estado-contratos'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/estado-contratos' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/estado-contratos' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Lista de Contratos Solicitados" />
          </ListItemButton>                

          <ListItemButton 
            component={Link} 
            to="/ventas/email" 
            selected={location.pathname === '/ventas/email'}
            sx={{ 
              pl: 4, 
              bgcolor: location.pathname === '/ventas/email' ? '#0A4575' : 'transparent',
              color: location.pathname === '/ventas/email' ? '#FFFFFF' : 'inherit',
              '&:hover': {
                bgcolor: '#e2eef7',
                color: '#000000',
              },
              '&.Mui-selected': {
                bgcolor: '#0A4575 !important',
                color: '#FFFFFF',
              },
              '&.Mui-selected:hover': {
                bgcolor: '#0A4575',
                color: '#FFFFFF',
              }
            }}
          >
            <ListItemText primary="Enviar Solicitud de Contrato por Email" />
          </ListItemButton>         
          </List>
        </Collapse>
      </List>

      {/* Pregunstas Frecuentes */}
      <ListItemButton onClick={handleAyudaClick}>
        <ListItemText primary="Ayuda" primaryTypographyProps={{ sx: { fontSize: '20px', fontFamily: 'Bakbak One, sans-serif', color: '#0A4575' }}}
        />                
        <ListItemText primary="" sx={{ fontWeight: 'bold', color: '#0A4575' }} />
          {openAyuda ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton> 
        <Collapse in={openAyuda} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <ListItemButton 
              component={Link} 
              to="/ventas/ayuda" 
              selected={location.pathname === '/ventas/ayuda'}
              sx={{ 
                pl: 4, 
                bgcolor: location.pathname === '/ventas/ayuda' ? '#0A4575' : 'transparent',
                color: location.pathname === '/ventas/ayuda' ? '#FFFFFF' : 'inherit',
                '&:hover': {
                  bgcolor: '#e2eef7',
                  color: '#000000',
                },
                '&.Mui-selected': {
                  bgcolor: '#0A4575 !important',
                  color: '#FFFFFF',
                }
              }}
            >
              <ListItemText primary="Preguntas Frecuentes" />
            </ListItemButton>
          </List>  
        </Collapse>
    </Box>
  );
};

export default VentasLayout;