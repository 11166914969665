// src/ayuda/dataAyuda.js

// Las claves que tendrás en tu ayuda, en el orden para "Anterior" / "Siguiente"
export const AYUDA_KEYS = [
  "cremacion",
  "exequias",
  "funerario",
  "parcelas",
  "columbarios",
];

// Para cada clave, define el objeto con la info de esa sección
export const AYUDA_DATA = {
  /**
   * CREMACIÓN
   */
  cremacion: {
    titulo: "Cremación",
    proceso: {
      nombre: "Cremación",
      definicion:
        "Consiste en someter a un cuerpo humano a altas temperaturas, hasta lograr que los restos humanos (óseos) se pulvericen mediante procesos mecánicos. En PAC se realiza a través de HORNOS, cuyas temperaturas de operación van de 400°C a 800°C.",
      caracteristicas:
        "2 operarios permanentes. Insumos de BIOSEGURIDAD de alta calidad.",
      insumos: "Uso de salas de ÚLTIMO ADIÓS, o JARDINES EXTERNOS, con bebidas calientes (té o café).",
      servicios_extras:
        "PRODUCTO ENTREGADOS: Restos de familiares en ÁNFORAS, así como certificado de cremación.",
    },
    etapas: [
      "1) Recepción y verificación de documentos",
      "2) Firma de autorizaciones (familiares)",
      "3) Emisión de Orden de servicio CREMATORIO",
      "4) Recepción de difunto Crematorio",
      "5) Revisión e inspección difunto",
      "6) Traslado Sala 'Último Adiós' (SI APLICA)",
      "7) Pesaje de Difunto",
      "8) Inclusión del difunto en horno asignado",
      "9) Revisión y remoción de forma periódica",
      "10) Barrido final - Restos en horno",
      "11) Sacar restos del horno",
      "12) Enfriamiento de restos",
      "13) Trituración de restos",
      "14) Incorporación de restos en bolsa plástica",
      "15) Se coloca bolsa plástica en ánforas",
      "16) Se emite CERTIFICADO DE CREMACIÓN",
      "17) Se entrega ÁNFORA al Dpto. comercial",
      "18) Se entregan ÁNFORAS + CERTIFICADO al familiar",
    ],
    preguntas: [
      {
        pregunta:
          "P- Para cremar, ¿cuál es lo máximo (Kg) en peso para un difunto?",
        respuesta:
          "R - En hornos PAC, máximo 140 Kg. Cuidado especial en proceso.",
      },
      {
        pregunta: "P- ¿Se puede cremar difuntos en procesos de investigación?",
        respuesta:
          "R- No se puede cremar ningún difunto en proceso de investigación (accidente, homicidio, etc.).",
      },
      {
        pregunta:
          "P- ¿Se puede cremar un difunto cuya muerte sea a través de suicidio?",
        respuesta: "R- NO.",
      },
      {
        pregunta:
          "P- ¿Se pueden cremar varios cuerpos en un solo proceso de cremación?",
        respuesta:
          "R- NO, va contra la ley, solo un caso a la vez.",
      },
      {
        pregunta: "P- ¿Cómo estoy seguro de que son las cenizas de mi familiar?",
        respuesta:
          "R- Existe un proceso de 'seguimiento' en cada caso, con cámaras que garantizan el resultado.",
      },
      {
        pregunta:
          "P - ¿Puedo ver el proceso de cremación de un familiar?",
        respuesta:
          "R- Si lo solicita con antelación, sí. Solo visible desde área administrativa.",
      },
      {
        pregunta:
          "P- ¿Tienen alguna forma de preservar el difunto en las instalaciones PAC?",
        respuesta:
          "R- Sí, existe un CUARTO FRÍO para resguardar un gran número de difuntos.",
      },
      {
        pregunta: "P - ¿Los restos se entregan el mismo día?",
        respuesta: "R- Sí, generalmente se entregan el mismo día.",
      },
      {
        pregunta:
          "P- ¿En qué tiempo se entregan las cenizas, una vez ingresado en CREMATORIO?",
        respuesta: "R- Máximo 3 horas, una vez ingresado en el horno.",
      },
      {
        pregunta:
          "P- ¿Es 'contaminante' el humo de los hornos en la cremación?",
        respuesta:
          "R- No, se hacen pruebas regulares según normativa del MINISTERIO DE AMBIENTE.",
      },
    ],
  },

  /**
   * HONRA DE EXEQUIAS
   */
  exequias: {
    titulo: "Honra de Exequias",
    proceso: {
      nombre: "Proceso: Honra de Exequias",
      definicion:
        "En PAC se define como un ACTO realizado en los JARDINES (zona verde), donde se colocan las cenizas en un lugar predeterminado. El acto puede ser acompañado por representantes de cualquier religión, según las buenas costumbres, con arreglos concertados.",
      caracteristicas:
        "PERSONAL: 2 operarios permanente. INSUMOS: Propios de la ceremonia, según acuerdo. SERVICIOS EXTRAS: Se puede contratar PALOMAS, ARREGLOS FLORALES, MÚSICOS. PRODUCTO ENTREGADOS: FABRICACIÓN Y COLOCACIÓN DE PLACA en muro del patio.",
    },
    etapas: [
      "1) Solicitud de servicio (AREA COMERCIAL)",
      "2) Definición tipo de Servicio",
      "3) Escogencia del LUGAR - realización ACTO",
      "4) Arreglo del lugar",
      "5) Apertura de orificio",
      "6) Recepción de las personas",
      "7) Realización del ACTO (con o sin ceremonia)",
      "8) Culminación del ACTO",
      "9) Inhumación de las cenizas (en caso de aplicar)",
      "10) Se retiran las personas",
      "11) Se procede a rellenar el hueco y colocar grama",
      "12) Se manda hacer PLACA que irá al muro",
      "13) Se coloca PLACA en MURO",
    ],
    preguntas: [
      {
        pregunta:
          "P- ¿Se puede colocar flores regularmente en el lugar donde hacen los ACTOS?",
        respuesta: "R- NO.",
      },
      {
        pregunta: "P- ¿Cuánto dura la ceremonia?",
        respuesta:
          "R- Normalmente, dura de 30 a 50 minutos. Si es un evento especial, puede durar hasta 2 horas.",
      },
      {
        pregunta:
          "P- ¿Los familiares pueden traer a un sacerdote u otra persona que presida el ACTO?",
        respuesta: "R- Sí, coordinando previamente.",
      },
    ],
  },

  /**
   * SERVICIO FUNERARIO
   */
  funerario: {
    titulo: "Servicio Funerario",
    proceso: {
      nombre: "Proceso: Servicio Funerario",
      definicion:
        "Servicio INTEGRAL que consiste en trasladar, preparar y acondicionar un difunto a fin de ser velado en cualquiera de las salas con que cuenta PAC.",
      caracteristicas:
        "PERSONAL: 3 operarios permanente. INSUMOS: Propios de la preparación, según normas de TANATOLOGÍA. SERVICIOS EXTRAS: Los servicios velatorios cuentan con bebidas calientes (té o café), venta de chucherías. PRODUCTO ENTREGADOS: Traslado del difunto al cementerio o crematorio destino.",
    },
    etapas: [
      "1) Recepción y verificación de documentos",
      "2) Definición tipo de Servicio",
      "3) Buscar el difunto",
      "4) Preparar al difunto (según convenio con familia)",
      "5) Difunto en ataúd - según contrato",
      "6) Llevar difunto a sala concertada",
      "7) Velación del difunto",
      "8) Traslado del difunto al cementerio o crematorio, previamente concertado",
    ],
    preguntas: [
      {
        pregunta:
          "P- ¿El servicio de velación incluye los traslados del difunto?",
        respuesta:
          "R- Solo aquellos de BÚSQUEDA en residencias o centros de salud, así como traslado al cementerio o crematorio una vez finalizado el servicio.",
      },
      {
        pregunta:
          "P- ¿Cuánto dura un difunto sin olores molestos, una vez preparado por un tanatólogo?",
        respuesta:
          "R- Para una preparación 'sencilla' puede durar 24 horas; para preparaciones 'especiales' de 48 a 72 horas, y para más de tres días, se recomiendan procesos de embalsamamiento.",
      },
      {
        pregunta: "P- ¿El servicio de velación incluye la PARCELA?",
        respuesta: "R- NO.",
      },
      {
        pregunta:
          "P- ¿El servicio de velación incluye alguna persona que guíe los rezos?",
        respuesta: "R- NO.",
      },
    ],
  },

  /**
   * PARCELAS
   */
  parcelas: {
    titulo: "Parcelas PAC",
    proceso: {
      nombre: "Proceso: Parcelas PAC",
      definicion:
        "Espacio físico compuesto por tres (3) bóvedas para la inhumación de igual número de difuntos. Ubicadas en lindos jardines, sin modificaciones de los propietarios a fin de conservar su homogeneidad.",
      caracteristicas:
        "PERSONAL: 2 operarios de mantenimiento. INSUMOS: (no especificado). SERVICIOS EXTRAS: (no especificado). PRODUCTO ENTREGADOS: Revestimiento de lápida en granito, una vez realizada la primera inhumación.",
    },
    etapas: [
      "1) Compra de parcela",
      "2) Activación de servicios de inhumación de parcela",
    ],
    preguntas: [
      {
        pregunta: "P- ¿Cuántos difuntos caben en una parcela?",
        respuesta: "R- 3",
      },
      {
        pregunta: "P- ¿Existen cuotas de mantenimiento?",
        respuesta:
          "R- Sí, para garantizar la preservación de las zonas verdes.",
      },
      {
        pregunta:
          "P- ¿La propiedad de la parcela incluye los servicios de entierros?",
        respuesta: "R- NO, esos servicios son aparte.",
      },
    ],
  },

  /**
   * COLUMBARIOS
   */
  columbarios: {
    titulo: "Columbarios (Nichos de Cenizas) PAC",
    proceso: {
      nombre: "Proceso: Columbarios",
      definicion:
        "Espacios físicos dispuestos en estructuras monolíticas llamadas CAPILLAS. Existe una capilla principal y capillas de contemplación, ubicadas en los jardines centrales del PAC.",
      caracteristicas:
        "PERSONAL: 3 operarios mantenimiento. INSUMOS: No especificado. SERVICIOS EXTRAS: No especificado. PRODUCTO ENTREGADOS: Nichos de columbario, revestidos en piedra de MÁRMOL, con su placa de identificación.",
    },
    etapas: [
      "1) Compra de COLUMBARIOS",
      "2) USO",
    ],
    preguntas: [
      {
        pregunta: "P- ¿Cuántas CENIZAS caben en un nicho?",
        respuesta:
          "R- En los sencillos, una ÁNFORA; y los dúplex, dos (2) ÁNFORAS.",
      },
      {
        pregunta: "P- ¿Existen cuotas de mantenimiento?",
        respuesta:
          "R- Sí, para garantizar la preservación de las zonas verdes.",
      },
    ],
  },
};
