import React from 'react';
import { AppBar, Toolbar, Button, Box, Avatar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {

  const navigate = useNavigate();

  const handleLogout = () => {
    // Mostrar notificación de "Cerrando sesión"
    toast.info('Cerrando sesión...', {
      position: 'bottom-right',
      autoClose: 3000, // Tiempo en ms
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

    // Retrasar el cierre de sesión durante 3 segundos
    setTimeout(() => {
      // Eliminar el token del localStorage
      localStorage.removeItem('access_token');
      localStorage.removeItem('vendedor_id');
      localStorage.removeItem('user_email');
      // Opcional: Eliminar otros datos de sesión, si tienes
      // localStorage.removeItem('user');

      // Redirigir al login o página principal
      navigate('/');
    }, 3000); // 3000 ms = 3 segundos
  };

  const loggedInUser = localStorage.getItem('usuario') || 'Usuario';


  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#0A4575' }}>
        <Toolbar>
          {/* Logo en el lado izquierdo */}
          <img
            src="https://www.cementeriopuertasalcielo.com.ve/wp-content/uploads/2023/02/Logo2x-03-1.webp"
            alt="Logo"
            style={{ width: '270px', marginTop: '15px', marginBottom: '15px', marginRight: '20px' }}
          />

          <Box sx={{ flexGrow: 1 }} /> {/* Empuja los botones hacia la derecha */}

          {/* Botón de Salir */}
          <Button
            sx={{
              color: 'white',
              backgroundColor: '#0A4575',
              '&:hover': { backgroundColor: '#000000' },
              marginRight: 2,
            }}
            onClick={handleLogout}
            startIcon={<LogoutIcon />}
          >
            Cerrar Sesión
          </Button>

          {/* Avatar y nombre de usuario */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
            <Avatar alt="Usuario" src="/images/avatar.png" />
            <Typography sx={{ color: 'white', marginLeft: 1 }}>{loggedInUser}</Typography>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Componente ToastContainer para mostrar notificaciones */}
      <ToastContainer />
    </>
  );
};

export default Header;
